import { mapActions, mapState } from "vuex";
import {
  DxDataGrid,
  DxColumn,
  DxFilterRow,
  DxScrolling,
  DxEditing,
  DxLookup,
  DxPaging,
  DxPager,
  DxHeaderFilter,
  DxButton,
  DxColumnFixing,
  DxStateStoring,
  DxSearch,
} from "devextreme-vue/data-grid";
import 'devextreme/dist/css/dx.light.css';
import CustomStore from 'devextreme/data/custom_store';
import { default as headers } from '../data/headers';
import { default as lists } from '../data/lists';

export default {
  components: {
    DxDataGrid,
    DxColumn,
    DxFilterRow,
    DxScrolling,
    DxEditing,
    DxLookup,
    DxPaging,
    DxPager,
    DxHeaderFilter,
    DxButton,
    DxColumnFixing,
    DxStateStoring,
    DxSearch,
  },
  data: (vm) => ({
    collections: [],
    renderComponent: true,
    renderComponentGrid: true,
    params: {},
    dialog: false,
    pageSize: 15,
    page: 1,
    per_page: 10,
    message: "",
    logs: [],
    loadingLogs: false,
    loadingRefreshChart: false,
    indexChart: -1,
    error: false,
    loadingPage: false,
    loadingClearSetting: false,
    loadingClearFilter: false,
    showFormException: false,
    reasonException: null,
    showMessageGenerate: false,
    typeGenerate: null,
    errors: {},
    loading: false,
    equivalence: false,
    showConfirmedTimes: false,
    meta: {},
    userSettingId: null,
    payloadFilter: null,
    showFormProtocol: false,
    numberProtocol: null,
    isEnabledProtocol: false,
    loadingUpdateRelocation: false,
    loadingUpdateRelocationItem: false,
    showStartUpdateRelocation: false,
    showStartUpdateRelocationItem: false,
    itemDelete: {},
    loadingMerge: false,
    showStartMerge: false,
    model: {},
    show: false,
    showForm: false,
    grids: [],
    loadingGetGrids: false,
    showImport: false,
    text: "Carregando dados...",
    loadingProgress: false,
    loadingNewRegisters: false,
    textDelete: "",
    deleting: false,
    filters: {},
    courses: [],
    gridCategories: [],
    totalItems: 0,
    baseGroups: [],
    showFilter: false,
    setedFilter: false,
    payload: [],
    showEdit: false,
    baseGroupCode: null,
    headers: headers,
    dialogChat: false,
    loadingCharts: false,
    selectedItem: null,
    updateAll: false,
    showMessageUpdateAll: false,
    showCharts: false,
    loadingFilterChart: false,
    newRegisterFiltered: false,
    loadingGeneralFilter: false,
    generateReport: false,
    loadingGenerateReport: false,
    indexFilterChart: -1,
    newRegisters: {},
    config: {
      validate_time_table: true,
      validate_ucdp: true,
    },
    charts: [],
    dataSource: new CustomStore({
        key: 'id',
        onLoading: function (loadOptions) { 
          loadOptions.with = 'disciplineEquivalence,baseGroupStatus,brand,institution,campus,campusParent,region,bigOccupationArea,occupationArea,category,course,qualifier,integrationShift,gridCategory,joinCategory,user';
          loadOptions.generateReport = vm.generateReport;

          if(vm.baseGroupCode){
            loadOptions.filter = [
              'base_group_code',
              '=',
              vm.baseGroupCode
            ]
          }

          Object.assign(loadOptions, vm.payloadFilter); 
        },
        load: (payload) => vm.ActionIndexOfferItemsScroll(payload).then((res) => {
          
          if(res.totalCount){
            vm.totalItems = res.totalCount;
          }

          return res;

        }).finally(() => {
          vm.loadingClearFilter = false;
          vm.loadingFilterChart = false;
          vm.loadingNewRegisters = false;
          vm.loadingGeneralFilter = false;
          vm.generateReport = false;
          vm.loadingGenerateReport = false;
          vm.reasonException = null;
          
          if(vm.baseGroupCode){
            
            vm.dataGrid.clearFilter();
            vm.dataGrid.columnOption('base_group_code', 'filterValue', vm.baseGroupCode);
            vm.baseGroupCode = null;
          }
        }),
        onUpdating: function (key, values) {
          
          if(vm.updateAll && vm.setedFilter){
            values.filterUpdate = {
              datagrid: vm.$refs['my-data-grid'].instance.getCombinedFilter(),
              general: vm.payloadFilter
            };
          }

          values.config = vm.config;
          values.protocol_number = vm.numberProtocol;
          values.reason_exception = vm.reasonException;
          
        },
        update: (key, values) => vm.ActionUpdateOfferItem({ id: key, ...values})
          .catch((error) => {
            
            vm.message = '';
            vm.errors = [];
            vm.error = false;
            
            vm.$nextTick(() => {
              vm.message = error.errors.reason_exception ? error.errors.reason_exception.toString() : error.errors.toString();
              vm.errors = error.errors;
              vm.error = true;
            });
            
            
          }).finally(() => {
            vm.updateAll = false;
            vm.config.validate_time_table = true;
            vm.config.validate_ucdp = true;
            vm.reasonException = null;
          }),  
    }),
    lists: lists,
  }),
  computed: {
    ...mapState('auth', ['user', 'acesso']),

    dataGrid: function() {
      
        return this.$refs['my-data-grid'].instance;

    },

    hideTools(){
      return this.user.read_only_offer;
    },

  },
  watch: {
    showFormProtocol(val){
      if(val){
        this.loadLogs();
      }
      
    },
  },
  created() {
    
    if(this.$route.query.code) {
      this.baseGroupCode = this.$route.query.code;
      this.showCharts = false;
    }
    
    this.loadNewRegisters();
    this.loadStatuses();

  },

  methods: {
    ...mapActions('grid', ['ActionIndexGridsOfferItems', 'ActionIndexOfferItemsScroll', 'ActionUpdateOfferItem', 'ActionFilterOfferItemLogs', 'ActionIndexOfferItemFilters', 'ActionIndexOfferItemsNews', 'ActionMergeOfferItems', 'ActionIndexOfferItemStatus', 'ActionUpdateRelocationOfferItems', 'ActionUpdateRelocationItemOfferItems', 'ActionGenerateGridOfferItems', 'ActionGenerateGridsOfferItems', 'ActionGenerateJoinsOfferItems']),
    ...mapActions('user', ['ActionIndexUserSettings', 'ActionCreateUserSetting', 'ActionUpdateUserSetting']),
    ...mapActions('baseGroup', ['ActionFindBaseGroups']),
    ...mapActions('chart', ['ActionIndexChartsOfferItems']),

    async loadStatuses() {

      var payload =  {
          find_columns: {
              active: 1,
          },
          column_order: 'id',
      }

      lists.statuses = [
        { id: null, name: null},
      ];

      await this.ActionIndexOfferItemStatus(payload)
              .then((res) => {
                  
                  res.data.forEach(element => {
                    this.lists.statuses.push(element);
                  });
                  
              })
              .finally(() => {
                
              })
  },

  getGrids(item){

    this.grids = [];
    this.loadingGetGrids = true;

    let payload = {
      id: item.id,
      withName: true,
    }

    this.ActionIndexGridsOfferItems(payload)
              .then((res) => {
                  this.grids = res.data;
                  this.showConfirmedTimes = true;
              })
              .finally(() => {
                this.loadingGetGrids = false;
              })

  },

  generateGrid(item, all){

    if(!item){
      return;
    }

    this.message = "";
    this.errors = [];
    this.error = false;
    
    let payload = {
        id: this.selectedItem.id,
        generateAll: all
      };  

      this.ActionGenerateGridOfferItems(payload)
          .then((res) => {
            this.message = res.message;

          }).catch((error) =>{
              this.message = error.message;
              this.errors = error.errors;
              this.error = true;
          })
          .finally(() => {
            
          });

  },

  generateGridsJoins(){

    this.message = "";
    this.errors = [];
    this.error = false;
    let method = this.typeGenerate == 'grids' ? 'ActionGenerateGridsOfferItems' : 'ActionGenerateJoinsOfferItems';
    
    this[method]()
        .then((res) => {
          
          this.message = res.message;

        }).catch((error) =>{
            this.message = error.message;
            this.errors = error.errors;
            this.error = true;
        })
        .finally(() => {
            this.showMessageGenerate = false;
        });

  },

    openCharts(){

      if(this.showCharts){
        this.showCharts = false;
      }else{

        if(this.charts.length == 0){
          this.loadCharts();
        }else{
          this.showCharts = true;
        }
        
      }

    },

    onGenerateReport(){

      this.generateReport = true;
      this.loadingGenerateReport = true;
      this.$nextTick(() => {
        this.dataGrid.refresh();
      });

    },
    
    loadCharts(index = -1){

      this.loadingCharts = index == -1;
      let payload = {};
      let indexes = [];
      this.indexChart = -1;

      if(index == -1){
        this.charts = [];
        indexes = [0,1,2,3,4,5,6,7,8];
      }else{
        indexes = [index];
        this.indexChart = index;
        this.loadingRefreshChart = true;
      }

      indexes.forEach(i => {
        
        payload.index = i;

        this.ActionIndexChartsOfferItems(payload)
          .then((res) => {
            
            if(index == -1){
              this.charts.push(res.data);
            }else{
              let find = this.charts.find(c => c.index == index);
              let indexOf = this.charts.indexOf(find);
              this.charts[indexOf] = res.data;
            }

          }).finally(() => {
            
            if(index == -1){
              if(this.charts.length == indexes.length){
                this.loadingCharts = false;
                this.showCharts = true;
              }
            }else{
              this.loadingCharts = false;
            }

            this.loadingRefreshChart = false;
            
            // this.loadingCharts = false;
            // this.showCharts = true;
          });
      });

    },

    loadLogs(){

      this.logs = [];
      
      if(!this.selectedItem){
        return;
      }
      
      this.loadingLogs = true;
      
      this.ActionFilterOfferItemLogs({id: this.selectedItem.id})
          .then((res) => {
            this.logs = res.data;
          }).finally(() => {
            this.loadingLogs = false;
          });

    },

    loadNewRegisters(){

      this.ActionIndexOfferItemsNews()
          .then((res) => {
            this.newRegisters = res.data;
          }).finally(() => {
            
          });

    },

    startMerge(){

      this.loadingMerge = true;

      this.message = "";
      this.errors = [];
      this.error = false;
            
      this.ActionMergeOfferItems()
          .then((res) => {
              this.message = 'Merge Iniciado com sucesso!';
              this.errors = ['Merge Iniciado com sucesso. Um e-mail será enviado no final do processo para a lista de e-mails configurado.'];
              this.error = false;
          }).finally(() => {
            this.loadingMerge = false;
            this.showStartMerge = false;
          });

    },

    startUpdateRelocations(){

      this.loadingUpdateRelocation = true;

      this.message = "";
      this.errors = [];
      this.error = false;
            
      this.ActionUpdateRelocationOfferItems()
          .then((res) => {
              this.message = 'Atualização de remanejamentos iniciado com sucesso!';
              this.errors = ['Atualização de remanejamentos iniciado com sucesso!'];
              this.error = false;
          }).finally(() => {
            this.loadingUpdateRelocation = false;
            this.showStartUpdateRelocation = false;
          });

    },

    startUpdateRelocationItem(item){

      if(!item || !item.base_group_code){
        return;
      }
      
      this.loadingUpdateRelocationItem = true;

      this.message = "";
      this.errors = [];
      this.error = false;

      this.ActionUpdateRelocationItemOfferItems({base_group_code: item.base_group_code})
          .then((res) => {
              this.message = 'Atualização de remanejamentos iniciado com sucesso!';
              this.errors = ['Atualização de remanejamentos iniciado com sucesso!'];
              this.error = false;
          }).finally(() => {
            this.loadingUpdateRelocationItem = false;
            this.showStartUpdateRelocationItem = false;
          });

    },

    filterNewsRegisters(){

      if(this.newRegisterFiltered){

        this.deleteFindColumnNewRegisters();
               

      }else{
        
        if(this.payloadFilter){
          
          if(!this.payloadFilter.find_columns){
            this.payloadFilter.find_columns = {};
          }
        
            this.payloadFilter.find_columns.merge = this.newRegisters.date;
            this.payloadFilter.find_columns.base_group_status_id = '1,2';
        }else{
          this.payloadFilter = {
            find_columns: {
              merge: this.newRegisters.date,
              base_group_status_id: '1,2',
            }
          }

        }

        this.newRegisterFiltered = true;
      }
      
      this.loadingNewRegisters = true;

      this.dataGrid.refresh();

    },

    downloadCompleted(error, message){

      this.error = false;
      this.errors = [];
      this.message = "";

      if(error){
        
        this.$nextTick(() => {
          this.error = true;
          this.message = message;
        });
        
        
      }

    },

    onCompletedFilter(event){
      
      this.showFilter = false;
      this.loadingGeneralFilter = true;
      this.newRegisterFiltered = false;
      this.payloadFilter = event;
      this.dataGrid.refresh();

    },

    filterChart(event, index){

      this.loadingFilterChart = true;
      this.indexFilterChart = index;
      
      if(!this.payloadFilter){
        this.payloadFilter = {};
      }

      this.payloadFilter.filterChart = event;
      this.deleteFindColumnNewRegisters();
      this.dataGrid.refresh();

    },

    deleteFindColumnNewRegisters(){

      if(this.payloadFilter && this.payloadFilter.find_columns && this.payloadFilter.find_columns.merge){
        delete this.payloadFilter.find_columns.merge;
      }

      if(this.payloadFilter && this.payloadFilter.find_columns && this.payloadFilter.find_columns.base_group_status_id){
        delete this.payloadFilter.find_columns.base_group_status_id;
      }

      this.newRegisterFiltered = false;

    },

    onCompletedRegister(){
      
      this.showForm = false;

      this.$nextTick(() => {
        this.dataGrid.refresh();
      });

    },

    onContentReady(e){
      this.changeSetedFilter();
    },

    changeSetedFilter(){
      
      let filters = (this.$refs['my-data-grid'] && this.$refs['my-data-grid'].instance) ? this.$refs['my-data-grid'].instance.getCombinedFilter() : null;

      if(this.newRegisterFiltered || filters || (this.payloadFilter && this.payloadFilter.filterChart)){
        this.setedFilter = true;
      }else{
        
        if(this.baseGroupCode){
          this.setedFilter = true;
        }else{
          this.setedFilter = false;
        }
        
        
      }
    },

    onEditorPreparing(event) {
      
      // const dataRow = event.component.getDataSource()._items[event.tabIndex];
        
        // if (
        //   event.dataField === "shiftDestiny" &&
        //   event.parentType === "dataRow"
        // ) {
        //   event.allowEditing = dataRow && dataRow.offerItemStatus === 3;
        // }

        let values = ['disciplineEquivalence.name', 'baseGroupStatus.name', 'observation_relocation', 'observation'];
        let dataField = event.dataField;
        
        if(event.parentType === "dataRow" && values.includes(dataField)){
          
          this.model = event.row.data;
          this.equivalence = dataField == 'disciplineEquivalence.name';
          this.showForm = true;
          
        }

    },

    onContextMenuPreparing(e) {
      if (e && e.row && e.row.rowType === "header") {
        const clearFilterMenu = {
          text: "Limpar filtro",
          onItemClick: function () {
            e.component.columnOption(e.column.index, 'filterValues', null);
          },
          disabled: !e.column.filterValues
        };

        const hideColumn = {
          text: "Ocultar Coluna",
          onItemClick: function () {
            //e.component.deleteColumn(e.column.index);
            e.component.columnOption(e.column.index, 'visible', false);
          },
          disabled: !e.column.visible
        };

        e.items.splice(3, 0, clearFilterMenu);
        e.items.splice(4, 0, hideColumn);
      }
    },

    

    onChangeCell(event) {
      
      // if(event.changes && event.changes[0]){
      //     const data = event.changes[0].data;
      //     this.updateItem(data); 
      // }
      
    },

    focusedRowChanged(e) {
      
      this.focusedRowIndex = e.rowIndex;

      if(e.row){
        this.selectedItem = e.row.data;
      }
      
    },

    
    onCellPrepared(e){

      // if(e.rowType === "data" && e.column.dataField === "offer_item_status_id"){

      //   if(e.value && e.value == 3 && e.data.work_time_control){
      //     e.cellElement.innerHTML = e.displayValue + ' ' + e.data.work_time_control;
      //   }
      // }

    },

    onRowPrepared(e){

      if (e.rowType == 'data' && e.data.base_group_status_id_calculate == 4) {  
        // e.rowElement.style.backgroundColor = '#e38e6f';
        //e.rowElement.className = e.rowElement.className.replace("dx-row-alt", "");  
        // e.rowElement.className = 'dx-datagrid-row-canceled'; 
        // e.rowElement.css('text-decoration', 'line-through');
        e.rowElement.style.textDecoration = 'line-through';
        e.rowElement.style.textDecorationColor = 'red';
      }else if(e.rowType == 'data' && e.data.merge == this.newRegisters.date && [1,2].includes(e.data.base_group_status_id_calculate)){
        e.rowElement.style.color = 'blue';
      }

    },

    changeUpdateAll(value){

      if(value){
        this.showMessageUpdateAll = true;
      }

    },

    cancelUpdateAll(){

      this.updateAll = false;
      

    },

    async loadState() {
      
      this.userSettingId = null;
      
      let payload = {
        find_columns: {
          user_id:              parseInt(this.user.id),
          user_setting_type_id: 1,
        }
      };

      const content = await this.ActionIndexUserSettings(payload)
        .then((res) => {
          
          if(res.data.length){
            
            let data = res.data[0];

            if(data.content){
              this.userSettingId = data.id;
              return JSON.parse(data.content);
            }
          }
          
          return null;
          
        });
       
        return content;
        
    },

    async saveState (state) {

      let payload = {
        user_id:              parseInt(this.user.id),
        user_setting_type_id: parseInt(1),
        content:              JSON.stringify(state),
      }
      
      await this.ActionCreateUserSetting(payload)
        .then((res) => {
          this.userSettingId = res.data.id;
        });

    },

    clearSettings () {

      if(!this.userSettingId){
        return;
      }

      this.loadingClearSetting = true;
      
      let payload = {
        id: this.userSettingId,
        content: null,
      }
      
      this.ActionUpdateUserSetting(payload)
          .then((res) => {
            
            this.renderComponentGrid = false;
      
            this.$nextTick(() => {
                this.renderComponentGrid = true;
            });
          }).finally(() => {
            this.loadingClearSetting = false;
          });

    },

    showFormFilter(){
      this.showFilter = true;
      this.changeSetedFilter();
    },

    clearFilter()
    {
      
      if(this.payloadFilter){
        delete this.payloadFilter.filterChart;
      }
     
      this.updateAll = false;
      this.deleteFindColumnNewRegisters();
      this.loadingClearFilter = true;
      this.baseGroupCode = null;

      this.$nextTick(() => {
        this.dataGrid.clearFilter();
        this.dataGrid.refresh();
      });
    
    },

    enableProtocol(enable){
      
      this.isEnabledProtocol = enable;
      this.showFormProtocol = false;

      if(!this.isEnabledProtocol){
        this.numberProtocol = null;
      }

    },

    updateItem(data) {
      
      this.error = false;
      this.errors = [];
      this.message = "";
      
      this.ActionUpdateOfferItem(data)
        .then((res) => {
          this.getData(false, this.payloadFilter);
        }).catch((error) =>{
          this.getData(false, this.payloadFilter);
          this.message = error.message;
          this.errors = error.errors;
          this.error = true;
        })
        .finally(() => {
          vm.updateAll = false;
        });
    },

    forceRerender () {
            
      this.showEdit = !this.showEdit;
      this.renderComponent = false;

      this.$nextTick(() => {
          this.renderComponent = true;
      });

    },

    
    
  },
};