export default [
    // { 
    //     text: "PERIODO_LETIVO", 
    //     value: "workTime.name",
    //     cssClass: 'column-fixed',
    //     visible: false
    // },
    { 
        text: "MARCA", 
        value: "brand.name",
        cssClass: 'column-fixed',
        notSorting: true,
        
    },
    { 
        text: "INSTITUICAO", 
        value: "institution.name",
        cssClass: 'column-fixed',
        notSorting: true,
    },
    { 
        text: "CAMPUS", 
        value: "campus.name",
        cssClass: 'column-fixed',
        notSorting: true,
    },
    { 
        text: "CAMPUS_GERENCIAL", 
        value: "campusParent.name",
        cssClass: 'column-fixed',
        notSorting: true,
    },
    { 
        text: "REGIONAL", 
        value: "region.name",
        cssClass: 'column-fixed',
        notSorting: true,
    },
    { 
        text: "GRANDE_AREA", 
        value: "bigOccupationArea.name",
        cssClass: 'column-fixed',
        notSorting: true,
    },
    { 
        text: "AREA_CONHECIMENTO", 
        value: "occupationArea.name",
        cssClass: 'column-fixed',
        notSorting: true,
    },
    { 
        text: "MODALIDADE", 
        value: "category.name",
        cssClass: 'column-fixed',
        notSorting: true,
    },
    { 
        text: "CURSO", 
        value: "course.name",
        cssClass: 'column-fixed',
        notSorting: true,
    },
    { 
        text: "QTDE_DE_SEMESTRES_DO_CURSO", 
        value: "total_course_periods",
        cssClass: 'column-fixed',
        notSorting: true,
    },
    { 
        text: "QTDE_DE_SEMESTRES_CURSADOS", 
        value: "total_completed_periods",
        cssClass: 'column-fixed',
        isible: false,
        notSorting: true,
    },
    { 
        text: "SEMESTRES_FALTANTES", 
        value: "total_missing_periods",
        cssClass: 'column-fixed',
         
    },
    { 
        text: "QUALIFICADOR", 
        value: "qualifier.name",
        cssClass: 'column-fixed',
        notSorting: true,
    },
    { 
        text: "TURNO", 
        value: "integrationShift.name",
        cssClass: 'column-fixed',
        notSorting: true,
    },
    { 
        text: "COD_COMPOSICAO", 
        value: "base_group_code",
        cssClass: 'column-fixed',
    },
    { 
        text: "COMPOSICAO", 
        value: "base_group_name",
        cssClass: 'column-fixed',
    },
    { 
        text: "SEMESTRE_DE_ENTRADA", 
        value: "work_time_entry",
        cssClass: 'column-fixed',
         
    },
    { 
        text: "DEMANDA_TOTAL_CAMPUS", 
        value: "students_total_campus",
        cssClass: 'column-green',
         
    },
    { 
        text: "QTDE_DE_ALUNOS", 
        value: "students_total",
        cssClass: 'column-green',
    },
    { 
        text: "QTDE_DE_ALUNOS_POS_EVASAO", 
        value: "students_final",
        cssClass: 'column-green',
        hideFilter: true,
        notSorting: true
    },
    { 
        text: "PROJECAO_ALUNOS", 
        value: "students_projection",
        cssClass: 'column-green',
        hideFilter: true,
        notSorting: true
    },
    { 
        text: "% EVASAO", 
        value: "evasion_percent",
        cssClass: 'column-fixed',
    },
    { 
        text: "ALUNOS_A_CURSAR", 
        value: "students_to_attend",
        cssClass: 'column-fixed',
    },
    { 
        text: "ALUNOS_DISPENSADOS", 
        value: "students_dismissed",
        cssClass: 'column-fixed',
    },
    { 
        text: "ALUNOS_DISPENSADOS_EQUIVALENCIA", 
        value: "students_dismissed_by_equivalence",
        cssClass: 'column-fixed',
    },
    { 
        text: "ALUNOS_REPROVADOS", 
        value: "students_failed",
        cssClass: 'column-fixed',
    },
    { 
        text: "ALUNOS_CURSANDO", 
        value: "students_studying",
        cssClass: 'column-fixed',
    },
    { 
        text: "SOMA_ALUNOS", 
        value: "students_sum",
        cssClass: 'column-fixed',
    },
    { 
        text: "MATRIZ", 
        value: "curriculum_name",
        cssClass: 'column-orange',
        notSorting: true,
    },
    { 
        text: "PERCURSO_DAS_OFERTAS", 
        value: "work_time_offer",
        cssClass: 'column-green',
    },
    { 
        text: "STATUS_DA_OFERTA", 
        value: "offer_item_status_id",
        dataSource: 'statuses',
        cssClass: 'column-purple column-editable',
        allowEditing: true,
        color: 'blue',
        notSorting: true,
    },
    { 
        text: "OFERTAR_EM", 
        value: "work_time_control",
        cssClass: 'column-green',
    },
    { 
        text: "SEMESTRE_INICIO", 
        value: "work_time_start",
        cssClass: 'column-green',
    },
    { 
        text: "UNIDADE_CURRICULAR", 
        value: "discipline_name",
        cssClass: 'column-fixed',
    },
    { 
        text: "CODIGO_UC", 
        value: "discipline_code",
        cssClass: 'column-fixed',
    },
    { 
        text: "TURMA_HORARIO", 
        value: "time_group_calculate",
        cssClass: 'column-fixed',
    },
    { 
        text: "UC_EQUIVALENTE", 
        value: "disciplineEquivalence.name",
        cssClass: 'column-purple column-editable',
        allowEditing: true,
    },
    // { 
    //     text: "CONFIRMA_PERCURSO", 
    //     value: "confirm_route",
    //     cssClass: 'column-fixed',
    // },
    { 
        text: "CADASTRO_SIAF", 
        value: "grid_modality_name",
        cssClass: 'column-fixed',
    },
    { 
        text: "DIGITAL", 
        value: "work_load_digital_total",
        cssClass: 'column-red',
    },
    { 
        text: "PRESENCIAL", 
        value: "work_load_classroom",
        cssClass: 'column-red',
    },
    { 
        text: "BUSCA_ATIVA", 
        value: "work_load_active_search",
        cssClass: 'column-red',
         
    },
    { 
        text: "RESUMO", 
        value: "work_load_summary",
        cssClass: 'column-red',
    },
    { 
        text: "TOTAL_CH", 
        value: "work_load_total",
        cssClass: 'column-red',
         
    },
    // { 
    //     text: "MODALIDADE_DA_OFERTA", 
    //     value: "gridModality.name",
    //     cssClass: 'column-fixed column-editable',
    // },
    { 
        text: "MODALIDADE_OFERTA_REGRA", 
        value: "grid_modality_id_rule",
        dataSource: 'gridModalities',
        cssClass: 'column-fixed column-editable',
        allowEditing: true,
    },
    { 
        text: "TIPO_JUNCAO", 
        value: "join_category_id",
        dataSource: 'joinCategories',
        cssClass: 'column-purple column-editable',
        allowEditing: true,
    },
    { 
        text: "OBSERVACAO_GESTOR", 
        value: "annotation",
        cssClass: 'column-fixed column-editable',
        allowEditing: true,
    },
    { 
        text: "OBSERVAÇÃO TRATATIVA BASE", 
        value: "base_treatment_note",
        cssClass: 'column-fixed column-editable',
        allowEditing: true,
    },
    { 
        text: "MERGE",
        value: "merge",
        cssClass: 'column-fixed',
    },
    { 
        text: "DIA_PROPOSTO", 
        value: "day_name_origin",
        cssClass: 'column-fixed',
    },
    { 
        text: "DIA_DA_SEMANA", 
        value: "offer_item_day_id",
        dataSource: 'days',
        cssClass: 'column-purple column-editable',
        allowEditing: true,
    },
    { 
        text: "ORIGEM_OFERTA", 
        value: "offer_origin",
        cssClass: 'column-fixed',
    },
    { 
        text: "PROTOCOLO-CHAMADO", 
        value: "protocol_number",
        cssClass: 'column-red',
        allowEditing: false,
    },
    { 
        text: "AJUSTES-CHAMADO", 
        value: "adjusts", 
        cssClass: 'column-red column-editable',
        allowEditing: true,
    },
    // { 
    //     text: "CORRECAO_OFERTA", 
    //     value: "offer_adjust",
    //     cssClass: 'column-fixed column-editable',
    //     allowEditing: true,
    // },
    { 
        text: "CLUSTER", 
        value: "code_cluster",
        cssClass: 'column-green column-editable',
        allowEditing: true,
        visible: false 
    },
    { 
        text: "UNIAO_D1_SALA", 
        value: "code_cluster_day_1",
        cssClass: 'column-green column-editable',
        allowEditing: true,
    },
    { 
        text: "NUM_TURMAS_D1_SALA", 
        value: "number_groups_day_1",
        cssClass: 'column-green column-editable',
        allowEditing: true,
    },
    { 
        text: "UNIAO_D2_SALA", 
        value: "code_cluster_day_2",
        cssClass: 'column-green column-editable',
        allowEditing: true,
    },
    { 
        text: "NUM_TURMAS_D2_SALA", 
        value: "number_groups_day_2",
        cssClass: 'column-green column-editable',
        allowEditing: true,
    },
    { 
        text: "UNIAO_D3_LAB", 
        value: "code_cluster_day_3",
        cssClass: 'column-orange-light column-editable',
        allowEditing: true,
         
    },
    { 
        text: "NUM_TURMAS_D3_LAB", 
        value: "number_groups_day_3",
        cssClass: 'column-green column-editable',
        allowEditing: true,
    },
    { 
        text: "% HIBRIDEZ", 
        value: "hybridity_percent",
        cssClass: 'column-fixed',
    },
    { 
        text: "COMUNIDADE_APRENDIZAGEM",
        value: "learningCommunity.name",
        cssClass: 'column-fixed',
    },
    { 
        text: "OBSERVAÇÃO DE STATUS", 
        value: "observation",
        cssClass: 'column-purple column-editable',
        allowEditing: true,
    },
    { 
        text: "STATUS_COMPOSICAO", 
        value: "baseGroupStatus.name",
        cssClass: 'column-purple column-editable',
        allowEditing: true,
        color: 'blue',
        notSorting: true,
    },
    // { 
    //     text: "MARCA_DESTINO", 
    //     value: "brandDestiny.name",
    //     cssClass: 'column-fixed',
    // },
        // { 
    //     text: "MODALIDADE_DA_OFERTA_DESTINO", 
    //     value: "gridModalityDestiny.name",
    //     cssClass: 'column-fixed',
    // },
    // { 
    //     text: "CAMPUS_DESTINO", 
    //     value: "campusDestiny.name",
    //     cssClass: 'column-fixed',
    // },
    // { 
    //     text: "CURSO_DESTINO", 
    //     value: "courseDestiny",
    //     cssClass: 'column-fixed',
    // },
    // { 
    //     text: "TURNO_DESTINO", 
    //     value: "shiftDestiny",
    //     cssClass: 'column-fixed',
    // },
    { 
        text: "OBSERVACAO_REMANEJAMENTO/ENCERRAMENTO", 
        value: "observation_relocation",
        cssClass: 'column-fixed column-editable',
        allowEditing: true,
    },
    { 
        text: "OBSERVACAO_GESTOR_REMANEJAMENTO/ENCERRAMENTO", 
        value: "observation_relocation_manager",
        cssClass: 'column-fixed column-editable',
        allowEditing: true,
    },
    { 
        text: "COD_REMANEJAMENTO", 
        value: "base_group_code_origin",
        cssClass: 'column-fixed',
    },
    // { 
    //     text: "COD_COMPOSICAO_DESTINO", 
    //     value: "baseGroupDestiny.code",
    //     cssClass: 'column-fixed',
    // },
        // { 
    //     text: "COMPOSICAO_DESTINO", 
    //     value: "baseGroupDestiny.name",
    //     cssClass: 'column-fixed',
    // },
    // { 
    //     text: "BLOQUEADO", 
    //     value: "blocked",
    //     cssClass: 'column-fixed',
    //     visible: false,
    // },
    // { 
    //     text: "CALOURO", 
    //     value: "freshmen",
    //     cssClass: 'column-fixed',
    //     visible: false,
    // },
    { 
        text: "USUARIO", 
        value: "user.name",
        cssClass: 'column-fixed',
        notSorting: true,
    },
    { 
        text: "DATA_ATUALIZACAO", 
        cssClass: 'column-fixed',
        value: "updated_at", 
    },
    { 
        text: "MOTIVO_EXCECAO", 
        value: "reason_exception",
        cssClass: 'column-purple',
    },
    { 
        text: "ID", 
        value: "id",
        hideFilter: false,
        notReordering: true,
        notResizing: true,
        notSearch: false,
        notSorting: true,
        notFiltering: false,
    },
]