var render = function() {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "main-container",
    {
      attrs: {
        icon: _vm.$route.meta.icon,
        title:
          _vm.$route.meta.title +
          (_vm.totalItems ? " (" + _vm.totalItems + " linhas)" : "")
      },
      scopedSlots: _vm._u([
        {
          key: "tools",
          fn: function() {
            return [
              _vm.setedFilter && !_vm.hideTools
                ? _c("s-switch", {
                    staticClass: "mt-4 mr-2",
                    attrs: {
                      "text-helper": null,
                      inset: "",
                      label: "Atualização em Lote"
                    },
                    on: { input: _vm.changeUpdateAll },
                    model: {
                      value: _vm.updateAll,
                      callback: function($$v) {
                        _vm.updateAll = $$v
                      },
                      expression: "updateAll"
                    }
                  })
                : _vm._e(),
              _vm.setedFilter && !_vm.hideTools
                ? _c("v-divider", { attrs: { vertical: "", inset: "" } })
                : _vm._e(),
              _vm.newRegisters.total
                ? _c(
                    "v-tooltip",
                    {
                      attrs: { bottom: "" },
                      scopedSlots: _vm._u(
                        [
                          {
                            key: "activator",
                            fn: function(ref) {
                              var on = ref.on
                              var attrs = ref.attrs
                              return [
                                _c(
                                  "v-btn",
                                  _vm._g(
                                    _vm._b(
                                      {
                                        staticClass: "text-none",
                                        attrs: {
                                          loading: _vm.loadingNewRegisters,
                                          stacked: "",
                                          icon: ""
                                        }
                                      },
                                      "v-btn",
                                      attrs,
                                      false
                                    ),
                                    on
                                  ),
                                  [
                                    _c(
                                      "v-badge",
                                      { attrs: { dot: "", color: "error" } },
                                      [
                                        _c(
                                          "v-icon",
                                          {
                                            attrs: {
                                              small: "",
                                              color: _vm.newRegisterFiltered
                                                ? "primary"
                                                : "grey"
                                            },
                                            on: {
                                              click: function($event) {
                                                $event.preventDefault()
                                                $event.stopPropagation()
                                                return _vm.filterNewsRegisters(
                                                  $event
                                                )
                                              }
                                            }
                                          },
                                          [_vm._v("mdi-flag-checkered")]
                                        )
                                      ],
                                      1
                                    )
                                  ],
                                  1
                                )
                              ]
                            }
                          }
                        ],
                        null,
                        false,
                        1362531354
                      )
                    },
                    [
                      _c("span", [
                        _vm.newRegisterFiltered
                          ? _c("span", [
                              _vm._v(
                                _vm._s(
                                  "Limpar filtro dos novos registros (" +
                                    _vm.newRegisters.date +
                                    ")"
                                ) + " "
                              )
                            ])
                          : _c("span", [
                              _vm._v(
                                _vm._s(
                                  "Exibir novos registros (" +
                                    _vm.newRegisters.date +
                                    ")"
                                ) + " "
                              )
                            ])
                      ])
                    ]
                  )
                : _vm._e(),
              _c("emc-table-icon", {
                attrs: {
                  "tool-tip":
                    "Motivo da Exceção" +
                    (_vm.reasonException ? ": " + _vm.reasonException : ""),
                  icon: "mdi-note-text-outline",
                  color: _vm.reasonException ? "primary" : "gray"
                },
                on: {
                  click: function($event) {
                    _vm.showFormException = true
                  }
                }
              }),
              _c("emc-table-icon", {
                attrs: {
                  "tool-tip": _vm.isEnabledProtocol
                    ? "Protocolo: " + _vm.numberProtocol
                    : "Alterações Chamado",
                  color: _vm.isEnabledProtocol ? "success" : "grey",
                  icon: _vm.isEnabledProtocol
                    ? "mdi-clipboard-check-outline"
                    : "mdi-clipboard-outline"
                },
                on: {
                  click: function($event) {
                    _vm.showFormProtocol = true
                  }
                }
              }),
              _vm.selectedItem
                ? _c("emc-table-icon", {
                    attrs: {
                      "tool-tip": "Formulário",
                      color: _vm.selectedItem.has_message ? "cyan" : "grey",
                      icon: "mdi-chat-processing-outline"
                    },
                    on: {
                      click: function($event) {
                        _vm.dialogChat = true
                      }
                    }
                  })
                : _vm._e(),
              _c("emc-table-icon", {
                attrs: {
                  "tool-tip": "Exibir/Ocultar Indicadores",
                  color: _vm.showCharts ? "primary" : "grey",
                  icon: "mdi-chart-bar-stacked"
                },
                on: {
                  click: function($event) {
                    return _vm.openCharts()
                  }
                }
              }),
              _c("emc-table-icon", {
                attrs: {
                  "tool-tip": "Limpar filtros da tabela",
                  loading: _vm.loadingClearFilter,
                  disabled: !_vm.setedFilter,
                  color: _vm.setedFilter ? "purple" : "grey",
                  icon: _vm.setedFilter
                    ? "mdi-filter-remove-outline"
                    : "mdi-filter-menu-outline"
                },
                on: {
                  click: function($event) {
                    return _vm.clearFilter()
                  }
                }
              }),
              _c("emc-table-icon", {
                attrs: {
                  "tool-tip": "Filtro Geral",
                  loading: _vm.loadingGeneralFilter,
                  icon: _vm.payloadFilter
                    ? "mdi-filter-remove-outline"
                    : "mdi-filter-menu-outline"
                },
                on: {
                  click: function($event) {
                    return _vm.showFormFilter()
                  }
                }
              }),
              _c("emc-table-icon", {
                attrs: {
                  loading: _vm.loadingClearSetting,
                  color: "error",
                  icon: "mdi-format-clear",
                  disabled: _vm.userSettingId == null,
                  "tool-tip": "Limpar preferências"
                },
                on: {
                  click: function($event) {
                    return _vm.clearSettings()
                  }
                }
              }),
              _vm.selectedItem
                ? _c("emc-button-icon", {
                    staticClass: "ml-2",
                    attrs: {
                      iconSmall: "",
                      loading: _vm.loadingGetGrids,
                      text: "Ver Horários da Oferta",
                      color: "primary",
                      icon: "mdi-calendar-clock"
                    },
                    on: {
                      click: function($event) {
                        return _vm.getGrids(_vm.selectedItem)
                      }
                    }
                  })
                : _vm._e(),
              _c("v-divider", { attrs: { vertical: "", inset: "" } }),
              _c("sys-pop-over-offer-item", {
                staticClass: "mr-2",
                attrs: { disabled: _vm.selectedItem == null },
                on: {
                  startMerge: function($event) {
                    _vm.showStartMerge = true
                  },
                  startUpdateRelocation: function($event) {
                    _vm.showStartUpdateRelocation = true
                  },
                  startUpdateRelocationItem: function($event) {
                    _vm.showStartUpdateRelocationItem = true
                  },
                  generateGrid: function($event) {
                    return _vm.generateGrid(_vm.selectedItem, $event)
                  },
                  generateGridAll: function($event) {
                    _vm.typeGenerate = $event
                    _vm.showMessageGenerate = true
                  }
                }
              }),
              !_vm.hideTools
                ? _c("sys-grid-offer-pop-over", {
                    staticClass: "mr-2",
                    attrs: { model: _vm.config }
                  })
                : _vm._e(),
              _c("v-divider", { attrs: { vertical: "", inset: "" } }),
              _c("sys-pop-over-import", {
                attrs: {
                  imports: [62],
                  icon: "mdi-cloud-upload",
                  color: "success",
                  openOnHover: "",
                  title: "Importações"
                }
              }),
              _c("sys-pop-over-report", {
                attrs: {
                  reports: [76, 75, 77, 102, 103, 109],
                  openOnHover: "",
                  color: "success",
                  icon: "mdi-microsoft-excel",
                  title: "Relatórios",
                  "show-message": ""
                }
              })
            ]
          },
          proxy: true
        }
      ])
    },
    [
      _c("emc-loader-progress", { attrs: { show: _vm.loadingProgress } }),
      _c("emc-message-offer-item", {
        attrs: { show: _vm.dialogChat, model: _vm.selectedItem },
        on: {
          "update:show": function($event) {
            _vm.dialogChat = $event
          }
        }
      }),
      _c("SysTeacherConfirmedTimesList", {
        attrs: { show: _vm.showConfirmedTimes, grids: _vm.grids },
        on: {
          "update:show": function($event) {
            _vm.showConfirmedTimes = $event
          }
        }
      }),
      _vm.loadingCharts
        ? _c("v-progress-linear", {
            attrs: { indeterminate: "", color: "yellow darken-2" }
          })
        : _vm._e(),
      _vm.showCharts
        ? _c(
            "v-row",
            { staticClass: "mb-1" },
            _vm._l(_vm.charts, function(chart, i) {
              return _c(
                "v-col",
                { key: i, attrs: { cols: "12", md: "3" } },
                [
                  _c(chart.component, {
                    tag: "v-component",
                    attrs: {
                      loadingRefresh:
                        _vm.loadingRefreshChart &&
                        chart.index == _vm.indexChart,
                      loading:
                        _vm.loadingFilterChart && i == _vm.indexFilterChart,
                      index: i,
                      data: chart
                    },
                    on: {
                      filterChart: function($event) {
                        return _vm.filterChart($event, i)
                      },
                      refresh: function($event) {
                        return _vm.loadCharts($event)
                      }
                    }
                  })
                ],
                1
              )
            }),
            1
          )
        : _vm._e(),
      _vm.isEnabledProtocol
        ? _c(
            "v-alert",
            { attrs: { type: "info" } },
            [
              _c(
                "v-row",
                { staticClass: "ma-0 pa-0" },
                [
                  _vm._v(
                    " Sistema liberado para as alterações do protocolo chamado número "
                  ),
                  _c("strong", { staticClass: "ml-1" }, [
                    _vm._v(_vm._s(_vm.numberProtocol))
                  ]),
                  _vm._v(". "),
                  _c("v-spacer"),
                  _c(
                    "v-btn",
                    {
                      staticClass: "mr-2",
                      attrs: { color: "error" },
                      on: {
                        click: function($event) {
                          $event.preventDefault()
                          $event.stopPropagation()
                          return _vm.enableProtocol(false)
                        }
                      }
                    },
                    [
                      _vm._v("Concluir Alterações"),
                      _c("v-icon", { attrs: { right: "", dark: "" } }, [
                        _vm._v("mdi-stop-circle-outline")
                      ])
                    ],
                    1
                  )
                ],
                1
              )
            ],
            1
          )
        : _vm._e(),
      _vm.renderComponentGrid
        ? _c(
            "DxDataGrid",
            {
              ref: "my-data-grid",
              attrs: {
                id: "gridContainer",
                focusedRowEnabled: true,
                "row-alternation-enabled": true,
                "allow-column-reordering": true,
                "allow-column-resizing": true,
                "column-auto-width": true,
                height: 500,
                "data-source": _vm.dataSource,
                "show-borders": true,
                "remote-operations": true,
                "word-wrap-enabled": false,
                "column-width": 200
              },
              on: {
                focusedRowChanged: function($event) {
                  return _vm.focusedRowChanged($event)
                },
                "cell-prepared": _vm.onCellPrepared,
                "row-prepared": _vm.onRowPrepared,
                saved: _vm.onChangeCell,
                update: _vm.onChangeCell,
                "editor-preparing": _vm.onEditorPreparing,
                "content-ready": _vm.onContentReady,
                "context-menu-preparing": _vm.onContextMenuPreparing
              }
            },
            [
              _c("DxStateStoring", {
                attrs: {
                  enabled: true,
                  type: "custom",
                  "custom-load": _vm.loadState,
                  "custom-save": _vm.saveState
                }
              }),
              _c("DxEditing", {
                attrs: {
                  "allow-updating": true,
                  "allow-adding": false,
                  "allow-deleting": false,
                  mode: _vm.showEdit ? "popup" : "cell",
                  useIcons: true
                }
              }),
              _c("DxScrolling", {
                attrs: {
                  mode: "virtual",
                  "use-native": true,
                  "show-scrollbar": "always",
                  "row-rendering-mode": "virtual"
                }
              }),
              _c(
                "DxHeaderFilter",
                { attrs: { visible: true } },
                [_c("DxSearch", { attrs: { enabled: true } })],
                1
              ),
              _c("DxFilterRow", {
                attrs: { visible: true, "apply-filter": "auto" }
              }),
              _c("DxColumnFixing", { attrs: { enabled: true } }),
              _vm.renderComponent && _vm.showEdit
                ? _c(
                    "DxColumn",
                    {
                      attrs: {
                        fixed: true,
                        "fixed-position": "left",
                        "allow-fixing": true,
                        "column-width": 100,
                        type: "buttons"
                      }
                    },
                    [_c("DxButton", { attrs: { name: "edit" } })],
                    1
                  )
                : _vm._e(),
              _vm._l(_vm.headers, function(header, index) {
                return _c(
                  "DxColumn",
                  {
                    directives: [
                      {
                        name: "show",
                        rawName: "v-show",
                        value: header.visible,
                        expression: "header.visible"
                      }
                    ],
                    key: index,
                    attrs: {
                      "allow-editing": header.allowEditing
                        ? header.allowEditing
                        : false,
                      "data-field": header.value,
                      caption: header.text,
                      fixed: header.fixed ? header.fixed : false,
                      "fixed-position": header.fixedPosition,
                      cssClass: header.cssClass,
                      "allow-header-filtering": !header.hideFilter,
                      "column-chooser": false,
                      "allow-reordering": !header.notReordering,
                      "allow-resizing": !header.notResizing,
                      "allow-search": !header.notSearch,
                      "allow-sorting": !header.notSorting,
                      "allow-filtering": !header.notFiltering,
                      "column-width": 100
                    }
                  },
                  [
                    header.dataSource
                      ? _c("DxLookup", {
                          attrs: {
                            "data-source": _vm.lists[header.dataSource],
                            "value-expr": "id",
                            "show-cancel-button": true,
                            "show-clear-button": true,
                            "display-expr": "name"
                          }
                        })
                      : _vm._e()
                  ],
                  1
                )
              }),
              _c("DxPaging", { attrs: { "page-size": _vm.pageSize } })
            ],
            2
          )
        : _vm._e(),
      _c("sys-grid-offer-filter", {
        attrs: { show: _vm.showFilter },
        on: {
          filter: _vm.onCompletedFilter,
          "update:show": function($event) {
            _vm.showFilter = $event
          }
        }
      }),
      _c("sys-register-offer-item", {
        attrs: {
          model: _vm.model,
          show: _vm.showForm,
          "register-type": _vm.equivalence ? "equivalence" : "relocation",
          title: _vm.equivalence
            ? "Unidade Curricular Equivalente"
            : "Remanejamento"
        },
        on: {
          onCompleted: _vm.onCompletedRegister,
          "update:show": function($event) {
            _vm.showForm = $event
          }
        }
      }),
      _c("emc-alert-snackbar", {
        attrs: {
          show: !!_vm.message.length,
          message: _vm.message,
          errors: _vm.errors,
          color: _vm.error ? "error" : "success"
        }
      }),
      _c("emc-alert-modal", {
        attrs: { show: _vm.showMessageUpdateAll, colorIcon: "error" },
        on: {
          "update:show": function($event) {
            _vm.showMessageUpdateAll = $event
          },
          clickCancel: _vm.cancelUpdateAll
        },
        scopedSlots: _vm._u([
          {
            key: "text",
            fn: function() {
              return [
                _c(
                  "div",
                  {
                    staticStyle: { "text-align": "center", "font-size": "20px" }
                  },
                  [
                    _c("strong", [
                      _vm._v(
                        " Ao habilitar essa opção, o valor inserido em uma das colunas listadas abaixo será replicado para os demais registros filtrados. "
                      )
                    ])
                  ]
                ),
                _c("br"),
                _c("ul", [
                  _c("li", [_vm._v("Status da Oferta")]),
                  _c("li", [_vm._v("Modalidade Oferta Regra")]),
                  _c("li", [_vm._v("Dia da Semana")]),
                  _c("li", [_vm._v("Tipo de Junção")]),
                  _c("li", [_vm._v("Ajustes Chamado")]),
                  _c("li", [_vm._v("Cluster")]),
                  _c("li", [_vm._v("União D1 Sala")]),
                  _c("li", [_vm._v("Num turmas D1 Sala")]),
                  _c("li", [_vm._v("União D2 Sala")]),
                  _c("li", [_vm._v("Num turmas D2 Sala")]),
                  _c("li", [_vm._v("União D3 Lab")]),
                  _c("li", [_vm._v("Num turmas D3 Lab")])
                ]),
                _c("br"),
                _c("div", { staticStyle: { "text-align": "center" } }, [
                  _c("p", [
                    _vm._v(
                      "*Os registros com Status da Oferta iguais a 'Ofertado', 'Ofertar' em outro período e Status da Composição diferentes de 'Ativa' não serão afetados!"
                    )
                  ])
                ])
              ]
            },
            proxy: true
          },
          {
            key: "actions",
            fn: function() {
              return [
                _c(
                  "v-btn",
                  {
                    attrs: { color: "success" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        _vm.showMessageUpdateAll = false
                      }
                    }
                  },
                  [
                    _vm._v("Ciente"),
                    _c("v-icon", { attrs: { right: "", dark: "" } }, [
                      _vm._v("mdi-thumb-up")
                    ])
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("emc-alert-modal", {
        attrs: {
          show: _vm.showFormException,
          title: "Motivo da Exceção",
          icon: "mdi-note-text-outline",
          textCancel: "Fechar"
        },
        on: {
          "update:show": function($event) {
            _vm.showFormException = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "text",
            fn: function() {
              return [
                _c(
                  "div",
                  {
                    staticStyle: { "text-align": "center", "font-size": "20px" }
                  },
                  [
                    _c("s-text-field", {
                      attrs: { autofocus: "", label: "Motivo da Exceção*" },
                      model: {
                        value: _vm.reasonException,
                        callback: function($$v) {
                          _vm.reasonException = $$v
                        },
                        expression: "reasonException"
                      }
                    })
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("emc-alert-modal", {
        attrs: {
          show: _vm.showFormProtocol,
          title: "Protocolo Chamado",
          icon: "mdi-clipboard-list-outline"
        },
        on: {
          "update:show": function($event) {
            _vm.showFormProtocol = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "text",
            fn: function() {
              return [
                _c(
                  "div",
                  {
                    staticStyle: { "text-align": "center", "font-size": "20px" }
                  },
                  [
                    _c("strong", [
                      _vm._v(
                        " Insira o número do Protocolo Chamado para habilitar edição da oferta "
                      )
                    ])
                  ]
                ),
                _c("br"),
                _c("s-text-field", {
                  attrs: {
                    disabled: _vm.isEnabledProtocol,
                    autofocus: "",
                    label: "Número Protocolo*"
                  },
                  model: {
                    value: _vm.numberProtocol,
                    callback: function($$v) {
                      _vm.numberProtocol = $$v
                    },
                    expression: "numberProtocol"
                  }
                }),
                _vm.logs && _vm.logs.length > 0
                  ? _c("span", [
                      _c("br"),
                      _vm._v(" Protocolos Anteriores "),
                      _c("br"),
                      _c(
                        "ul",
                        _vm._l(_vm.logs, function(log, index) {
                          return _c("li", { key: index }, [
                            _vm._v(" " + _vm._s(log) + " ")
                          ])
                        }),
                        0
                      )
                    ])
                  : _vm._e(),
                _c("v-checkbox", {
                  staticClass: "ml-2",
                  attrs: {
                    label: "Protocolo Deferido",
                    disabled: !_vm.numberProtocol || _vm.isEnabledProtocol
                  },
                  model: {
                    value: _vm.deferredProtocol,
                    callback: function($$v) {
                      _vm.deferredProtocol = $$v
                    },
                    expression: "deferredProtocol"
                  }
                })
              ]
            },
            proxy: true
          },
          {
            key: "actions",
            fn: function() {
              return [
                !_vm.isEnabledProtocol
                  ? _c(
                      "v-btn",
                      {
                        attrs: {
                          disabled:
                            !_vm.deferredProtocol || !_vm.numberProtocol,
                          color: "success"
                        },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            $event.stopPropagation()
                            return _vm.enableProtocol(true)
                          }
                        }
                      },
                      [
                        _vm._v("Habilitar"),
                        _c("v-icon", { attrs: { right: "", dark: "" } }, [
                          _vm._v("mdi-clipboard-check-outline")
                        ])
                      ],
                      1
                    )
                  : _c(
                      "v-btn",
                      {
                        attrs: { color: "error" },
                        on: {
                          click: function($event) {
                            $event.preventDefault()
                            $event.stopPropagation()
                            return _vm.enableProtocol(false)
                          }
                        }
                      },
                      [
                        _vm._v("Concluir Alterações"),
                        _c("v-icon", { attrs: { right: "", dark: "" } }, [
                          _vm._v("mdi-stop-circle-outline")
                        ])
                      ],
                      1
                    )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("emc-alert-modal", {
        attrs: {
          text:
            "Essa ação irá iniciar o merge de acordo com data mais recente. Deseja iniciar o merge?",
          disabled: _vm.deleting,
          show: _vm.showStartMerge
        },
        on: {
          "update:show": function($event) {
            _vm.showStartMerge = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "actions",
            fn: function() {
              return [
                _c(
                  "v-btn",
                  {
                    attrs: { color: "primary", loading: _vm.loadingMerge },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.startMerge($event)
                      }
                    }
                  },
                  [
                    _vm._v("SIM - Iniciar Merge "),
                    _c("v-icon", { attrs: { right: "", dark: "" } }, [
                      _vm._v("mdi-table-merge-cells")
                    ])
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("emc-alert-modal", {
        attrs: {
          text:
            "Essa ação irá iniciar a atualização dos remanejamentos. Deseja iniciar a atualização?",
          show: _vm.showStartUpdateRelocation
        },
        on: {
          "update:show": function($event) {
            _vm.showStartUpdateRelocation = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "actions",
            fn: function() {
              return [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      color: "primary",
                      loading: _vm.loadingUpdateRelocation
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.startUpdateRelocations($event)
                      }
                    }
                  },
                  [
                    _vm._v("SIM - Iniciar"),
                    _c("v-icon", { attrs: { right: "", dark: "" } }, [
                      _vm._v("mdi-account-switch-outline")
                    ])
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("emc-alert-modal", {
        attrs: {
          text:
            "Essa ação irá iniciar a atualização do remanejamento da turma origem selecionada. Deseja iniciar a atualização?",
          show: _vm.showStartUpdateRelocationItem
        },
        on: {
          "update:show": function($event) {
            _vm.showStartUpdateRelocationItem = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "actions",
            fn: function() {
              return [
                _c(
                  "v-btn",
                  {
                    attrs: {
                      color: "primary",
                      loading: _vm.loadingUpdateRelocationItem
                    },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.startUpdateRelocationItem(_vm.selectedItem)
                      }
                    }
                  },
                  [
                    _vm._v("SIM - Iniciar"),
                    _c("v-icon", { attrs: { right: "", dark: "" } }, [
                      _vm._v("mdi-account-switch-outline")
                    ])
                  ],
                  1
                )
              ]
            },
            proxy: true
          }
        ])
      }),
      _c("emc-alert-modal", {
        attrs: {
          text: "Deseja iniciar o processo?",
          show: _vm.showMessageGenerate
        },
        on: {
          "update:show": function($event) {
            _vm.showMessageGenerate = $event
          }
        },
        scopedSlots: _vm._u([
          {
            key: "actions",
            fn: function() {
              return [
                _c(
                  "v-btn",
                  {
                    attrs: { color: "primary" },
                    on: {
                      click: function($event) {
                        $event.preventDefault()
                        $event.stopPropagation()
                        return _vm.generateGridsJoins()
                      }
                    }
                  },
                  [_vm._v("SIM - Iniciar")]
                )
              ]
            },
            proxy: true
          }
        ])
      })
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }